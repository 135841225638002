.logo {
  margin: 16px;
  color : white;
  font-size: 17px;
  padding-left: 11.5px;
}

.container{
  display : flex;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 130px;
  background-image: url(./assets/images/background-cotrugli.jpg);
  min-height : 99vh;
  background-position: right;
}

